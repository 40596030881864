.sr-main-body {
  align-self: center;
  width: calc(100% - 16rem);
  max-width: 1440px;
  padding: 4rem 8rem;
  flex: 1;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(105, 7, 53, 0.125));

  @media screen and (max-width: 600px) {
    padding: 2rem 2.5rem;
    width: calc(100% - 5rem);
  }
}