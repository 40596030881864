.sr-router-anchor {
  padding: 0.75rem;
  margin: 0.25rem;
  text-decoration: none;
  color: #dedede;

  &:hover {
    color: var(--main-font-hover);
    background-color: var(--accent-hover-color);
  }

  @media screen and (max-width: 600px) {
    padding: 0.5rem;
    margin: 0.25rem;
  }
}