.sr-openfortress-page {
  > hr {
    align-self: center;
    width: 4rem;
    margin: 2rem 0;
  }

  &__emojis {
    font-size: 2rem;
    align-self: center;
  }

  @media screen and (max-width: 600px) {
    &__emojis {
      font-size: 1.75rem;
    }
  }
}