.sr-navbar {
  flex-direction: row;
  background-color: var(--accent-color);
  padding: 0 2rem;
  align-items: center;


  &__page-links {
    flex: 1;
    flex-direction: row;
    justify-content: center;
  }

  &__donate-button {
    justify-content: center;
    justify-self: flex-end;
  }

  @media screen and (max-width: 600px) {
    padding: 0 0.5rem;
  }
}