.sr-heading {
  > .sr-page-title, 
  > .sr-page-subtitle {
    padding-bottom: 1rem;
  }

  > hr {
    margin-bottom: 3rem;
    width: 15rem;
  }

  @media screen and (max-width: 600px) {
    > .sr-page-title, 
    > .sr-page-subtitle {
      padding-bottom: 0.75rem;
    }

    > hr {
      margin-bottom: 2.25rem;
      width: 10rem;
    }
  }
}