.sr-header {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2.5rem 2rem;
  background: linear-gradient(0deg, rgba(17, 17, 34, 0.85), rgba(105, 7, 53, 0.9)), url(../../__assets/images/biotrophic/03.png);
  background-position: center;

  &__pfp-image {
    border: rgba(255, 255, 255, 0.1) solid 0.4rem;
    border-radius: 100%;
    width: 8rem;
    height: 8rem;
  }

  &__heading {
    word-break: break-word;
    .sr-page-subtitle {
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 1rem 1rem;
    gap: 0.5rem;

    &__heading {
      .sr-page-title {
        font-size: 1rem;
        padding-bottom: 0.25rem;
      }

      .sr-page-subtitle {
        font-size: 0.85rem;
      }
    }

    &__pfp-image {
      width: 3rem;
      height: 3rem;
    }
  }
}