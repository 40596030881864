.sr-footer {
  justify-content: center;
  flex-direction: row;
  gap: 0.5rem;
  padding: 1rem;
  font-size: 1.25rem;
  font-family: 'Micro 5';

  @media screen and (max-width: 600px) {
    font-size: 0.75rem;
  }
}