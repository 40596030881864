.sr-links-page {
  flex: 1;

  &__link-cards-container {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    align-items: flex-start;
  }
}