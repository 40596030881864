.sr-donate {
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  text-decoration: none;
  color: #dedede;
  padding: 0.75rem;
  margin: 0.25rem;

  &:hover {
    color: #caa;
    background-color: rgba(0, 0, 25, 0.25);
  }

  > img {
    height: 1rem;
  }

  > p {
    font-size: 1.025rem;
  }

  @media screen and (max-width: 600px) {
    padding: 0.5rem;
    margin: 0.25rem;

    > img {
      height: 0.75rem;
    }
  }

  @media screen and (max-width: 700px) {
    > p {
      display: none;
    }
  }
}