.sr-link-card {
  min-width: 12rem;
  height: fit-content;
  background-color: #281d35;
  padding: 1.5rem 2rem;
  border-radius: 0.75rem;

  > ul {
    flex-wrap: wrap;
    gap: 2rem;

    > li {
      display: list-item;
      list-style-type: '-> ';
      list-style-position: inside;

      > a {
        display: inline;
      }
    }
  }

  > h3 {
    font-size: 1.25rem;
  }

  > hr {
    margin: 1.25rem;
    margin-left: 0;
  }

  @media screen and (max-width: 600px) {
    min-width: 12rem;

    > h3 {
      font-size: 1rem;
    }

    > hr {
      margin: 1rem;
      margin-left: 0;
    }

    > ul {
      flex-wrap: wrap;
      gap: 1.25rem;
    }
  }
}