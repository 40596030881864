@font-face {
  font-family: 'DM Sans';
  src: local('DM_Sans'), url(./__assets/fonts/DM_Sans/DMSans-VariableFont_opsz\,wght.ttf) format('opentype');
}

@font-face {
  font-family: 'Micro 5';
  src: local('Micro_5'), url(./__assets/fonts/Micro_5/Micro5-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Space Mono';
  src: local('Space_Mono'), url(./__assets/fonts/Space_Mono/SpaceMono-Regular.ttf) format('opentype');
}

:root {
  --main-font: #bebebe;
  --main-font-hover: #caa;
  --dark-bg: #112;
  --accent-color: rgb(105, 7, 53);
  --accent-hover-color: rgb(78, 6, 40);
}

* {
  margin: 0;
  padding: 0;
}

html, body {
  background-color: var(--dark-bg);
  color: var(--main-font);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  scrollbar-color: var(--accent-color) var(--accent-hover-color);
  scrollbar-width: thin;
}

body * {
  font-family: 'Space Mono';
  font-size: 1.025rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  scrollbar-color: var(--accent-color) var(--accent-hover-color);
  scrollbar-width: thin;
}


ol {
  gap: 1rem;
}

li {
  display: list-item;
  list-style-position: inside;
}

u, b, em, i, p {
  display: inline;
  font-size: 1.2rem;
  font-weight: 600;
}

button {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  body * {
    font-size: 0.65rem;
  }

  u, b, em, i, p {
    font-size: 0.85rem;
  }
}