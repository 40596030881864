@keyframes scan-line {
  from {
    top: 0;
    opacity: 0.25;
  }
  to {
    top: calc(100% - 0.25rem - 1rem);
    opacity: 0.1;
  }
}

@keyframes shift {
  0% {
    background-position: 1% 1%;
  }
  50% {
    background-position: -23% -1%;
  }
  100% {
    background-position: 1% 1%;
  }
}

@keyframes shift-text {
  0% {
    transform: translate(0.5%, 0.5%);
  }
  50% {
    transform: translate(-1%, -2%);
  }
  100% {
    transform: translate(0.5%, 0.5%);
  }
}

.sr-tab-window {
  flex: 1;

  &__tabs {
    flex-direction: row;
    overflow-x: auto;
  }

  &__tab {
    display: block;
    background-color: var(--accent-hover-color);
    white-space: nowrap;
    border: var(--accent-color) solid 0.25rem;
    border-bottom: none;

    &:first-child {
      border-right: none;
    }

    &:last-child {
      border-left: none;
    }

    > button {
      padding: 0.5rem 2rem;
      background: none;
      border: none;
      color: var(--main-font);
      font-family: 'Micro 5';
      font-size: 1.75rem;
    }

    &.-selected {
      background-color: var(--accent-color);
    }
  }

  &__view-window {
    border: var(--accent-color) solid 0.25rem;
    flex: 1;
    min-height: 75vh;
    justify-content: center;
    align-items: center;

    > button {
      display: none;
    }

    > * {
      height: 100%;
      width: 100%;
      display: flex;
      border: none;
      background-color: var(--accent-hover-color);
      justify-content: center;
      align-items: center;
    }

    &[data-fullscreen] {
      > button {
        display: block;
        height: min-content;
      }
    }
  }

  &__no-content {
    animation: shift 0.075s linear infinite;
    background: linear-gradient(rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.97)), url(../../__assets/images/static.jpg);
    position: relative;

    > p {
      opacity: 0.5;
      font-size: 1.75rem;
      animation: shift-text 0.1s linear infinite;
      text-shadow: -2px 0px 4px rgba(255, 0, 0, 0.5), 2px 0px 4px rgba(0, 0, 255, 0.75);
      mix-blend-mode: hard-light;
    }

    &:before,
    &:after {
      z-index: 2;
      mix-blend-mode: hard-light;
      content: '';
      position: absolute;
      height: 1rem;
      width: 100%;
    }

    &::before {
      animation: scan-line 5s linear infinite;
      background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0));
    }

    &:after {
      animation: scan-line 4.95s linear infinite;
      background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, .5), rgba(255, 255, 255, 0));
    }
  }

  &__power {
    flex: 1;
    justify-content: flex-start;
    gap: 1rem;

    > svg {
      transition: fill 0.5s;
      width: 2rem;
      height: 2rem;
    }

    &.-on > svg {
      fill: green;
    }

    &.-off > svg {
      fill: red;
    }
  }

  &__buttons {
    padding: 0 1rem;
    flex-direction: row;
    background: black;
  }

  &__button {
    background: none;
    border: none;
    color: var(--main-font);
    padding: 1rem 0.5rem;
    align-items: center;
    flex-direction: row;
  }

  @media screen and (max-width: 600px) {
    &__tab {
      padding: 0.25rem 1rem;
      
      > button {
        font-size: 1rem;
      }
    }

    &__no-content {

      > p {
        font-size: 1rem;
      }
    }

    &__power {
      gap: 0.5rem;

      > svg {
        width: 1rem;
        height: 1rem;
      }
    }

    &__buttons {
      padding: 0 0.5rem;
    }
  }
}